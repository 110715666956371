
.personal-center-index {
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  .num-grid-box {
    display: grid;
    grid-template-rows: 100px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    > div {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      color: #fff;
    }
  }
  .table-grid-box {
    display: grid;
    grid-template-rows: 400px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 24px 0;
    > div {
      border: 1px solid #e7e7e7;
      padding: 20px;
    }
  }
}
